import NavBarDirective from './directives/NavBarDirective';
import ReturnToAMP_AND from './directives/return-to-amp_AND/ReturnToAMP';
import PncdHasBeenRemovedBanner_AND from './directives/PncdHasBeenRemovedBanner_AND/PncdHasBeenRemovedBanner';
import 'edge/platform/widgets/pagestructure/module';
import 'edge/quoteandbind/app';
import 'edge/guidance/app';
import 'edge/faq/app';
import 'l10nModule';
import 'gw-portals-util-angular';
import 'edge/platform/facebook/module';
import 'edge/platform/segmentation/module';
import 'edge/platform/widgets/mediasidebar/module';
import 'edge/platform/widgets/footer/module';
import SetVersionDocumentTitle from 'gw-portals-platform-angular/versionName/SetVersionDocumentTitle';
import 'styles-portal/sass/modules.scss';

const GTM_BRAND_ID = {
    'tbb': 'GTM-PHKPFHMN',
    'itb': 'GTM-M9HCPR',
    'dgt': 'GTM-N7ZMKV',
    'toy': 'GTM-TDMPRPZ',
    'aut': 'GTM-PP9TWHS',
    'lex': 'GTM-K77PZDL',
    'kin': 'GTM-KDF69B2',
    'kfi': 'GTM-PNLQ7KJQ'
};

const BRANDS = [
    'tbb',
    'itb',
    'dgt',
    'toy',
    'lex',
    'aut',
    'kin',
    'kfi'
];

const LIVECHAT_BRAND_ID = {
    'tbb': 'XQMAk83v51bLOOZfhblTng%3d%3d',
    'itb': 'jdQLcaOYjl4pFjQbihDmew%3d%3d',
    'dgt': 'ec_bc27m7zXYEI1PSFssFdg%3d%3d',
    'aut': '5mQyAOGjIIzmVPi8FPwgyA%3d%3d',
    'lex': 'dzP7_bqTRXpFPS_fARj3uxtg%3d%3d',
    'toy': 'SNl3GrUkbMQ3mMBAiUeL6Q%3d%3d',
    'kin': 'YyknoTcfPQdA7Lxde_fNPYA%3d%3d',
    'kfi': 'mJYQhT_bCLdHxtKMO0U1UuA%3d%3d'
};
const FBPIXEL_BRAND_ID = {
    'itb': '305124740145016',
};

const PINPIXEL_BRAND_ID = {
    'itb': '2613534347598',
    'dgt': '2613098465746'
};

const TWITTERPIXEL_BRAND_ID = {
    'itb': 'o5m8r',
    'dgt': 'o5m8t'
};

export default angular.module('portal.app', [
    'ui.router',
    'edge.platform.widgets.pagestructure',
    'edge.platform.l10n',
    'edge.quoteandbind.app',
    'edge.guidance.app',
    'edge.faq.app',
    'edge.platform.util',
    'edge.platform.facebook',
    'edge.platform.campaign',
    'edge.platform.widgets.mediasidebar',
    'edge.platform.widgets.footer',
    'edge.platform.segmentation'
])
    .config(['FaqServiceProvider', 'FAQ_CONFIG', (FaqServiceProvider, FAQ_CONFIG) => {
        FaqServiceProvider.setSections(FAQ_CONFIG.sections);
    }])

    .config(['FacebookServiceProvider', 'FACEBOOK_CONFIG', 'DEBUG_MODE', (FacebookServiceProvider, FACEBOOK_CONFIG, DEBUG_MODE) => {
        FacebookServiceProvider.setDebugMode(DEBUG_MODE);
        FacebookServiceProvider.setAppId(FACEBOOK_CONFIG.appId);
        FacebookServiceProvider.setShareDialogData(FACEBOOK_CONFIG.shareDialog);
    }])

    .config(['CampaignDataServiceProvider', 'CAMPAIGN_PARAMS_CONFIG', (CampaignDataServiceProvider, CAMPAIGN_PARAMS_CONFIG) => {
        CampaignDataServiceProvider.setUrlParamsConfig(CAMPAIGN_PARAMS_CONFIG.campaign);
    }])

    .directive('gwPortalNavBar', NavBarDirective)
    .directive('andReturnToAmp', ReturnToAMP_AND) // ANDIE
    .directive('andPncdHasBeenRemovedBanner', PncdHasBeenRemovedBanner_AND)

    .constant('qnb-gateway-contact-details', {
        phoneNumber: '1-800-555-0199'
    })

    .run(['$translate', 'brandingData', '$state', ($translate, brandingData, $state) => {
        // ANDIE prevent OOTB default branding to be shown if no branding is specified
        if (!BRANDS.includes(brandingData)) {
            $state.go('');
        }

        if (brandingData === 'dgt'){
            window.location.replace("/quoteandbuy/app/html/thankyou.html");
        }

        SetVersionDocumentTitle({
            $translate,
            // "portal.app.versionName.title[.${VERSION}]" l10n ID (with a version, if available for the app) will be used
            translationIdBase: `and.portal.app.versionName.title.${brandingData}` // ANDIE
        });
        // ANDIE favicon
        // document.head = document.head || document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        const oldLink = document.getElementById('and-dynamic-favicon');
        link.id = 'and-dynamic-favicon';
        link.rel = 'icon';
        link.href = `../../common/styles/images/favicon/favicon-${brandingData}.ico`;
        link.type = 'image/x-icon';
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }])
    .run(['brandingData', (brandingData) => {
        // ANDIE - IE/Edge .append() polyfill
        // https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append#Polyfill
        (function (arr) {
            arr.forEach((item) => {
                if (item.hasOwnProperty('append')) {
                    return;
                }
                Object.defineProperty(item, 'append', {
                    configurable: true,
                    enumerable: true,
                    writable: true,
                    value: function append() {
                        const argArr = Array.prototype.slice.call(arguments);
                        const docFrag = document.createDocumentFragment();
                        argArr.forEach((argItem) => {
                            const isNode = argItem instanceof Node;
                            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                        });
                        this.appendChild(docFrag);
                    }
                });
            });
        }([Element.prototype, Document.prototype, DocumentFragment.prototype]));

        // ANDIE Google TGM
        const gtm = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer', '${GTM_BRAND_ID[brandingData]}');`;
        const gtmURL = `<iframe src='https://www.googletagmanager.com/ns.html?id=${GTM_BRAND_ID[brandingData]} height='0' width='0' style='display:none;visibility:hidden'></iframe>`;
        const script = document.createElement('script');
        script.append(gtm);
        document.head.appendChild(script);
        const noscript = document.createElement('noscript');
        document.body.insertBefore(noscript, document.body.firstChild);
        noscript.append(gtmURL);

        // ANDIE TBB Tagging
        if (brandingData === 'tbb') {
            const tbbTag = 'https://nexus.ensighten.com/tescobank/Telematics/Bootstrap.js';
            const tbbTagScript = document.createElement('script');
            tbbTagScript.setAttribute('src', tbbTag);
            tbbTagScript.setAttribute('type', 'text/javascript');
            document.head.appendChild(tbbTagScript);
        }

    }])
    .run(['brandingData', (brandingData) => {
        const chatScript = document.createElement('script');
        const chatLogic = `BY.chat({
                            baseDomain: 'commbox.io/',                  
                            appsSubDomain: 'apps',
                            streamId:  '${LIVECHAT_BRAND_ID[brandingData]}',                  
                            trackerStreamId: 'isk0iIgqDx0aBC5T_fihKVA%3d%3d'
                        });`;
        chatScript.append(chatLogic);
        document.body.appendChild(chatScript);

    }])

    .run(['brandingData', (brandingData) => {
        // ANDIE - IE/Edge .append() polyfill
        // https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append#Polyfill
        (function (arr) {
            arr.forEach((item) => {
                if (item.hasOwnProperty('append')) {
                    return;
                }
                Object.defineProperty(item, 'append', {
                    configurable: true,
                    enumerable: true,
                    writable: true,
                    value: function append() {
                        const argArr = Array.prototype.slice.call(arguments);
                        const docFrag = document.createDocumentFragment();
                        argArr.forEach((argItem) => {
                            const isNode = argItem instanceof Node;
                            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                        });
                        this.appendChild(docFrag);
                    }
                });
            });
        }([Element.prototype, Document.prototype, DocumentFragment.prototype]));

        if (brandingData !== 'tbb'){
        //Facebook Tracking Pixel
        const fpt = `!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${FBPIXEL_BRAND_ID[brandingData]}');
                    fbq('track', 'PageView');
                    `;
        const ftpURL = `<iframe src='https://www.facebook.com/tr?id=${FBPIXEL_BRAND_ID[brandingData]}&ev=PageView&noscript=1'></iframe>`;
        const script = document.createElement('script');
        script.append(fpt);
        document.head.appendChild(script);
        const noscript = document.createElement('noscript');
        document.body.insertBefore(noscript, document.body.firstChild);
        noscript.append(ftpURL);
        }

    }])

    .run(['brandingData', (brandingData) => {
        // ANDIE - IE/Edge .append() polyfill
        // https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append#Polyfill
        (function (arr) {
            arr.forEach((item) => {
                if (item.hasOwnProperty('append')) {
                    return;
                }
                Object.defineProperty(item, 'append', {
                    configurable: true,
                    enumerable: true,
                    writable: true,
                    value: function append() {
                        const argArr = Array.prototype.slice.call(arguments);
                        const docFrag = document.createDocumentFragment();
                        argArr.forEach((argItem) => {
                            const isNode = argItem instanceof Node;
                            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                        });
                        this.appendChild(docFrag);
                    }
                });
            });
        }([Element.prototype, Document.prototype, DocumentFragment.prototype]));

        if (brandingData !== 'tbb'){
        // Pinterest Tracking Pixel
        const ptp = `!function(e){if(!window.pintrk){window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
              n=window.pintrk;n.queue=[],n.version="3.0";var
              t=document.createElement("script");t.async=!0,t.src=e;var
              r=document.getElementsByTagName("script")[0];
              r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', '${PINPIXEL_BRAND_ID[brandingData]}', {em: '<user_email_address>'});
            pintrk('page');
            `;
        const ptpURL = `<iframe src='https://ct.pinterest.com/v3/?event=init&tid=${PINPIXEL_BRAND_ID[brandingData]}&pd[em]=<hashed_email_address>&noscript=1'></iframe>`;
        const script = document.createElement('script');
        script.append(ptp);
        document.head.appendChild(script);
        const noscript = document.createElement('noscript');
        document.body.insertBefore(noscript, document.body.firstChild);
        noscript.append(ptpURL);
        }

    }])


    .run(['brandingData', (brandingData) => {
        // ANDIE - IE/Edge .append() polyfill
        // https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append#Polyfill
        (function (arr) {
            arr.forEach((item) => {
                if (item.hasOwnProperty('append')) {
                    return;
                }
                Object.defineProperty(item, 'append', {
                    configurable: true,
                    enumerable: true,
                    writable: true,
                    value: function append() {
                        const argArr = Array.prototype.slice.call(arguments);
                        const docFrag = document.createDocumentFragment();
                        argArr.forEach((argItem) => {
                            const isNode = argItem instanceof Node;
                            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                        });
                        this.appendChild(docFrag);
                    }
                });
            });
        }([Element.prototype, Document.prototype, DocumentFragment.prototype]));

        if (brandingData !== 'tbb'){
        // Twitter Tracking Pixel
        const ttp = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('init','${TWITTERPIXEL_BRAND_ID[brandingData]}');
        twq('track','PageView');
        `;
        const ttpURL = `<iframe src='https://analytics.twitter.com/i/adsct?txn_id=${TWITTERPIXEL_BRAND_ID[brandingData]}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0'></iframe>`;
        const script = document.createElement('script');
        script.append(ttp);
        document.head.appendChild(script);
        const noscript = document.createElement('noscript');
        document.body.insertBefore(noscript, document.body.firstChild);
        noscript.append(ttpURL);
        }

    }])


    // ANDIE
    // Offset scroll to allow for header and quote page premium to not hide the content.
    // This isn't a perfect match since mobile/desktop and brand heights are different.
    // For a pixel-perfect solution we need to get the current height of the elements with javascript and offset with it.
    .run(['$anchorScroll', function ($anchorScroll) {
        $anchorScroll.yOffset = 115;
    }])
    // ANDIE
    // Scroll to top on every state change.
    .run(['ScrollService_AND', '$rootScope', function (ScrollService_AND, $rootScope) {
        $rootScope.$on('$stateChangeSuccess', () => {
            ScrollService_AND.scrollToTop();
        });
    }]);