const BRAND_COVERAGES = {
    itb: {
        Breakdown: {
            CoverageCode: 'PMRoadRescueCov',
            TermCode: 'PMRoadRescueLevel',
            Options: ['Level1', 'Level2', 'Level3'],
            SelectedTerm: null
        },
        Legal: {
            CoverageCode: 'PMLegalExpensesCov',
            TermCode: null,
            Options: null,
            SelectedTerm: null
        },
        CourtesyCar: {
            CoverageCode: 'TMEnhancedCourtesyCarCov',
            TermCode: null,
            Options: null,
            SelectedTerm: null
        },
        KeyCover: {
            CoverageCode: 'PMKeyProtect',
            TermCode: null,
            Options: null,
            SelectedTerm: null
        }
    },
    tbb: {
        Breakdown: {
            CoverageCode: 'PMBreakdownCov',
            TermCode: 'PMBreakdownLevel',
            Options: ['Value', 'Finest', 'EuroPlus'],
            SelectedTerm: null
        },
        KeyCover: {
            CoverageCode: 'PMKeyCov',
            TermCode: null,
            Options: null,
            SelectedTerm: null
        }
    },
    kfi: {
        Breakdown: {
            CoverageCode: 'TMBreakdownCov',
            TermCode: 'TMBreakdownLevel',
            Options: ['Level1', 'Level2', 'Level3'],
            SelectedTerm: null
        },
        Legal: {
            CoverageCode: 'PMLegalExpensesCov',
            TermCode: null,
            Options: null,
            SelectedTerm: null
        },
        CourtesyCar: {
            CoverageCode: 'TMEnhancedCourtesyCarCov',
            TermCode: null,
            Options: null,
            SelectedTerm: null
        },
        DrivingAbroad: {
            CoverageCode: 'PMDrivingAbroadCov',
            TermCode: 'TMDrivingAbroadDays',
            Options: ['3', '90'],
            SelectedTerm: null
        },
        Trailer: {
            CoverageCode: 'PMTrailerCov',
            TermCode: null,
            Options: null,
            SelectedTerm: null
        }
    }
};

export default {
    mapAggsCoverages: (queryParams, brandingData) => {
        const brandCoverages = BRAND_COVERAGES[brandingData];

        const aggCoverages = [];

        let coverageNames = Object.keys(brandCoverages);
        let paramNames = Object.keys(queryParams);

        let coveragesInParams = coverageNames.filter(element => paramNames.includes(element));

        for (var cov in brandCoverages) {
            if(coveragesInParams.includes(cov)) {
                var covObj = brandCoverages[cov];
                var covOptions = covObj.Options;

                if(!!covOptions) {
                    var matchingParamVal = queryParams[cov];
                    var matchingTerm = covOptions.filter(x => x.toLowerCase() === matchingParamVal.toLowerCase());
                    if(!!matchingTerm && matchingTerm.length > 0) {
                        covObj.SelectedTerm = matchingTerm[0];
                        aggCoverages.push(covObj);
                    }
                }
                else {
                    var matchingParamVal = queryParams[cov];
                    if(cov.toLowerCase() === matchingParamVal.toLowerCase()) {
                        aggCoverages.push(covObj);
                    }
                }
            }
        }

        return aggCoverages;
    },
    
    setHiddenVersion: (quotes) => {
        if (quotes.length === 1) {
            return;
        }
        for (let i = 0; i < quotes.length; i++) {
            if (quotes[i].isCustom === true) {
                switch (i) {
                    case 0:
                    case 1:
                        quotes[2].isHidden = true;
                        break;
                    case 2:
                    default:
                        quotes[0].isHidden = true;
                }
            }
        }
    },

    getUnadjustedAmount: (amount = {amount: 0}) => { Math.floor((amount.amount / 12) * 100) / 100},

    getMonthlyAmount: (cov) => {
        Object.assign(
        {},
        cov.amount || {currency: 'gbp', amount: 0},
        {amount: getUnadjustedAmount(cov.amount)} // Do not round
        );
    },

    getBaseOffering: (quotes) => { quotes.find((quote) => quote.isCustom === true); },

    getCoverage: (patternCode, offering) => {
        offering.lobs.personalMotor
        .pmVehicleCoverages[0].coverages.find((cov) => cov.codeIdentifier === patternCode)
    },

    getCoverageTermFromCoverage: (coverage, covTermPatternCode) => {
        coverage.terms.find((term) => term.patternCode === covTermPatternCode)
    },

    getCoverageTerm: (covPatternCode, covTermPatternCode, offering) => {
        const coverage = this.getCoverage(covPatternCode, offering);
        if (coverage) {
            return this.getCoverageTermFromCoverage(coverage, covTermPatternCode);
        }
    },

    updateQuotePrice: (submissionData, coverage, covTerm) => {
        let premium = submissionData.baseOffering.premium.total;
        if(!!covTerm) {
            premium = premium + covTerm.amount;
        }
        else {
            premium = premium + coverage.amount
        }
        submissionData.baseOffering.premium.total = premium;
        return submissionData;
    },

    updateQuote: (submissionData, CustomQuoteService) => {
        let baseOffering = submissionData.quoteData.offeredQuotes.find((quote) => quote.isCustom === true)
        return CustomQuoteService.updateQuote({
            sessionUUID: submissionData.sessionUUID,
            quoteID: submissionData.quoteID,
            coverages: baseOffering.lobs,
            quote: baseOffering,
            voluntaryExcess: submissionData.lobData.personalMotor.coverables.pmVehicles[0].voluntaryExcess,
            periodStartDate: submissionData.periodStartDate,
            ncdProtection: submissionData.lobData.personalMotor.coverables.pmVehicles[0].ncdProtection,
            ncdCosts: submissionData.lobData.personalMotor.coverables.pmVehicles[0].ncdCosts
        })
            .then((qdd) => {
                qdd.quoteData.offeredQuotes = qdd.quoteData.offeredQuotes.map(quote => {
                    if (qdd.lobData.coverages) {
                        quote.lobs = qdd.lobData.coverages;
                    }
                    if (qdd.lobData.offerings) {
                        const offering = qdd.lobData.offerings.find(offer => offer.branchName === quote.branchName);
                        quote.lobs = offering ? offering.coverages : null;
                    }
                    return quote;
                });
                return qdd;
            });
    },

    updateCoverageSelection: (selected, patternCode, submissionData, quotePriceUpdate = false, prevSelected = false) => {
        let coverage = null;
        submissionData.quoteData.offeredQuotes.forEach((quote) => {
            const offeredQuote = quote.value != null ? quote.value : quote;
            coverage = offeredQuote.lobs.personalMotor.pmVehicleCoverages[0].coverages.find((cov) => cov.codeIdentifier === patternCode);
            coverage.selected = selected;
            coverage.updated = true; // Required to trigger update in PC
            
            if(quotePriceUpdate) {
                if(offeredQuote.isCustom === true) {
                    let premium = Number(offeredQuote.premium.total.amount);
                    if(selected && prevSelected === false) {
                        premium = premium + coverage.amount.amount;
                    }
                    else if(prevSelected === true) {
                        premium = premium - coverage.amount.amount;
                    }
                    offeredQuote.premium.total.amount = premium.toFixed(2);
                }
            }
        });
        return submissionData;
    },

    updateCoverageTermSelection: (chosenTerm, covPatternCode, covTermPatternCode, submissionData, quotePriceUpdate = false, prevSelectedTerm = null) => {
        let coverage = null;
        let covTerm = null;
        submissionData.quoteData.offeredQuotes.forEach((quote) => {
            const offeredQuote = quote.value != null ? quote.value : quote;
            coverage = offeredQuote.lobs.personalMotor.pmVehicleCoverages[0].coverages.find((cov) => cov.codeIdentifier === covPatternCode);
            coverage.selected = chosenTerm !== null;
            covTerm = coverage.terms.find((term) => term.patternCode === covTermPatternCode);
            covTerm.chosenTerm = chosenTerm;
            covTerm.updated = true; // Required to trigger update in PC
            
            if(quotePriceUpdate) {
                if(offeredQuote.isCustom === true) {
                    let chosenCovTerm = covTerm.options.find((opt) => opt.code === chosenTerm);
                    let premium = Number(offeredQuote.premium.total.amount);
                    if(prevSelectedTerm != null) {
                        let prevCovTerm = covTerm.options.find((opt) => opt.code === prevSelectedTerm);
                        premium = premium - prevCovTerm.amount.amount;
                    }
                    if(chosenTerm != null) {
                        premium = premium + chosenCovTerm.amount.amount;
                    }
                    offeredQuote.premium.total.amount = premium.toFixed(2);
                }
            }
        });
        return submissionData;
    }
}